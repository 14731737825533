import styled from "styled-components";

export const StyledLogo = styled.div`
  display: flex;
  height: 32px;
  margin: 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  color: #fff;
`;
