import {
  HddOutlined,
  QrcodeOutlined,
  SettingOutlined,
  LinkOutlined,
  UploadOutlined,
  UserOutlined,
  BookOutlined,
} from "@ant-design/icons";

import Login from "../../pages/Login";
import TagManager from "../../pages/TagManager";
import Environment from "../../pages/Environment";
import Orders from "../../pages/Orders";
import TagKit from "../../pages/TagKit";
import Users from "../../pages/Users";
import Manifest from "../../pages/Manifest";
import CompanyId from "../../pages/CompanyId";

export const routes = [
  {
    component: Login,
    path: "/login",
    needAuth: false,
    isSuperAdmin: false,
  },
  {
    component: TagManager,
    path: "/tag-manager",
    to: "tag-manager",
    title: "Tag manager",
    icon: <HddOutlined style={{ fontSize: "1.2rem" }} />,
    needAuth: true,
    isSuperAdmin: false,
  },
  {
    component: TagKit,
    path: "/tag-kit",
    to: "tag-kit",
    title: "Tag kit",
    icon: <QrcodeOutlined style={{ fontSize: "1.2rem" }} />,
    needAuth: true,
    isSuperAdmin: true,
  },
  {
    component: Users,
    path: "/users",
    to: "users",
    title: "Manage users",
    icon: <UserOutlined style={{ fontSize: "1.2rem" }} />,
    needAuth: true,
    isSuperAdmin: true,
  },
  {
    component: Environment,
    path: "/environment",
    to: "environment",
    title: "Environment",
    icon: <SettingOutlined style={{ fontSize: "1.2rem" }} />,
    needAuth: true,
    isSuperAdmin: true,
  },
  {
    component: Manifest,
    path: "/manifest",
    to: "manifest",
    title: "Upload manifest",
    icon: <UploadOutlined style={{ fontSize: "1.2rem" }} />,
    needAuth: true,
    isSuperAdmin: false,
  },
  {
    component: CompanyId,
    path: "/company-id",
    to: "company-id",
    title: "Get Company ID",
    icon: <BookOutlined style={{ fontSize: "1.2rem" }} />,
    needAuth: true,
    isSuperAdmin: false,
  },
  {
    component: Orders,
    path: "/orders",
    to: "orders",
    title: "Order links",
    icon: <LinkOutlined style={{ fontSize: "1.2rem" }} />,
    needAuth: true,
    isSuperAdmin: false,
  },
];
