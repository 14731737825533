import React, { useEffect } from "react";
import { Form, Input, Select, Row, Col } from "antd";

const { Item } = Form;

export default function UserAddForm({
  user,
  onInputChange,
  onFinish,
  onRoleChange,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Form
      form={form}
      id="add-user-form"
      layout="vertical"
      onChange={onInputChange}
      onFinish={() => {
        form.resetFields();
        onFinish();
      }}
    >
      <Row>
        <Col style={{ width: "calc(100% - 180px)" }}>
          <Item
            initialValue={""}
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input name="name" />
          </Item>
        </Col>

        <Col offset={1}>
          <Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Role is required" }]}
          >
            <Select
              style={{ width: 160 }}
              name="role"
              onChange={onRoleChange}
              options={[
                { value: "super_admin", label: "Super Admin" },
                { value: "company_admin", label: "Company Admin" },
              ]}
            />
          </Item>
        </Col>
      </Row>
      <Col>
        <Item
          label="E-mail"
          name="email"
          rules={[
            () => ({
              validator(_, value = "") {
                const rex = new RegExp(
                  /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g
                );
                if (!rex.exec(value)) {
                  return Promise.reject("Valid email is required");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input name="email" />
        </Item>
      </Col>
      <Col>
        <Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input name="password" type="password" />
        </Item>
      </Col>
    </Form>
  );
}
