import { StyledLogo } from "./styled";
import Image from "../../assets/images/logo-small.svg";

export default function Logo() {
  return (
    <StyledLogo>
      <img src={Image} alt="DIRX2 logo" />
    </StyledLogo>
  );
}
