import { Button, Layout as AntdLayout } from "antd";

import Menu from "../Menu";
import Logo from "../Logo";
import { PageContent, StyledHeader } from "./styled";
import history from "../ProtectedRoute/history";
import { clearAccessToken } from "../../helpers";

function Layout({ children, user }) {
  const { Content, Sider } = AntdLayout;

  const logOut = () => {
    clearAccessToken();
    history.go("/");
  };

  return (
    <AntdLayout>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <Logo />
        <Menu role={user.role} />
      </Sider>
      <AntdLayout style={{ marginLeft: 200, minHeight: "100vh" }}>
        <StyledHeader>
          <Button onClick={logOut}> Log out </Button>
        </StyledHeader>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "auto",
          }}
        >
          <PageContent>{children}</PageContent>
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
}

export default Layout;
