import React, { useEffect } from "react";
import { Form, Input, Select, Row, Col } from "antd";

const { Item } = Form;

export default function UserEditForm({ user, onInputChange, onFinish, onRoleChange }) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(user);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <Form
      form={form}
      id="edit-user-form"
      layout="vertical"
      onChange={onInputChange}
      onFinish={() => {
        form.resetFields();
        onFinish();
      }}
    >
      <Row>
        <Col style={{ width: "calc(100% - 180px)" }}>
          <Item
            initialValue={""}
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input name="name" />
          </Item>
        </Col>

        <Col offset={1}>
          <Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Role is required" }]}
          >
            <Select
              style={{ width: 160 }}
              name="role"
              onChange={onRoleChange}
              options={[
                { value: "super_admin", label: "Super Admin" },
                { value: "company_admin", label: "Company Admin" },
              ]}
            />
          </Item>
        </Col>
      </Row>
      <Col>
        <Item
          label="E-mail"
          name="email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input name="email" />
        </Item>
      </Col>
    </Form>
  );
}
