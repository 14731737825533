import { useEffect, useState } from "react";
import { List, Tooltip, notification, Modal } from "antd";
import { CopyOutlined, DeleteOutlined, CopyFilled } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";

import { URLS } from "../../constants";
import { StyledIcon } from "./styled";
import { useModal } from "../../hooks";
import { useQuery } from "react-query";

export default function Orders({ token }) {
  const [linksList, setLinksList] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [
    isModalShown,
    setModalShown,
    onModalOkAction,
    onModalCancelAction,
  ] = useModal();

  const { refetch } = useQuery(
    "orders",
    () =>
      axios
        .get(URLS.FETCH_ORDERS, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data?.length) {
          setLinksList([...data]);
        }
      },
    }
  );

  const { refetch: deletingFetch } = useQuery(
    "orders",
    () =>
      axios
        .delete(URLS.FETCH_ORDERS, {
          params: { id: itemToDelete._id },
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => res.data),
    {
      enabled: false,
      onSuccess: (data) => {
        if (data.deletedCount) {
          notification.info({
            message: `Link successfully deleted`,
            duration: 2,
          });
        }
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onCopy = () => {
    notification.open({
      message: "Copied",
      icon: <CopyFilled />,
      duration: 2,
    });
  };

  const showDeleteModal = (item) => {
    setItemToDelete(item);
    setModalShown()();
  };

  const handleOk = () => {
    onDelete(itemToDelete);
    onModalOkAction()();
  };

  const onDelete = async (item) => {
    await deletingFetch();
    setLinksList((links) => links.filter((l) => l.link !== item.link));
  };

  const sortedLinksByDate = linksList.sort((a, b) =>
    b.date.localeCompare(a.date)
  );

  return (
    <>
      <Modal
        title="Delete link"
        visible={isModalShown}
        onOk={handleOk}
        onCancel={onModalCancelAction()}
      >
        Are you sure you want to delete link?
      </Modal>
      <List
        style={{ height: "auto" }}
        itemLayout="horizontal"
        dataSource={sortedLinksByDate}
        renderItem={(item) => (
          <List.Item
            actions={[
              <CopyToClipboard text={item.link} onCopy={onCopy}>
                <Tooltip placement="top" title="Copy">
                  <StyledIcon>
                    <CopyOutlined />
                  </StyledIcon>
                </Tooltip>
              </CopyToClipboard>,
              <Tooltip
                placement="top"
                title="Delete"
                onClick={() => showDeleteModal(item)}
              >
                <StyledIcon color="#ff4d4f">
                  <DeleteOutlined />
                </StyledIcon>
              </Tooltip>,
            ]}
          >
            <List.Item.Meta
              title={<a href={item.link}>{item.link}</a>}
              description={`Date: ${new Date(
                item.date
              ).toUTCString()}, Company Id:  ${item.company}, Rolls: ${
                item.rolls
              }, Tags: ${item.tags};`}
            />
          </List.Item>
        )}
      />
    </>
  );
}
