import { useState, useEffect } from "react";
import { Button, notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import { PlusOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "react-query";
import axios from "axios";

import { EnvironmentForm } from "../../components/Forms";
import { ENVIRONMENT_MODEL, URLS, DATABASE_TYPES } from "../../constants";
import { TopControlsBlock, ContentInner } from "../styled";

export default function Environment({ token }) {
  const [environments, updateEnvironments] = useState([]);
  const [testConnectionEnv, setTestConnectionEnv] = useState(null);
  const { refetch } = useQuery(
    "envData",
    () =>
      axios
        .get(URLS.FETCH_ENVIRONMENT, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => res.data),
    { enabled: false, onSuccess: (data) => updateEnvironments(data) }
  );
  const mutation = useMutation((data) =>
    axios.put(URLS.SAVE_ENVIRONMENT, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const test = async () => {
      axios
        .post(URLS.TEST_ENVIRONMENT_CONNECTION, testConnectionEnv, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data) {
            notification.success({ message: "Connected", duration: 1.5 });
          }
        })
        .catch((e) => {
          notification.error({
            message: `Cannot create connection`,
            duration: 1.5,
          });
        });
    };
    if (testConnectionEnv) {
      test();
      setTestConnectionEnv(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testConnectionEnv]);

  const updateEnvironment = (field, value, envId) => {
    updateEnvironments((envs) =>
      envs.map((env) => {
        if (envId !== env.id) return env;
        return { ...env, [field]: value };
      })
    );
  };

  const onInputChange = (envId) => (e) => {
    const { name, value } = e.target;
    updateEnvironment(name, value, envId);
  };

  const onClientSelect = (envId) => (client) => {
    updateEnvironment("client", client, envId);
  };

  const addEnvironment = () => {
    updateEnvironments((envs) => [
      ...envs,
      { id: uuidv4(), ...ENVIRONMENT_MODEL },
    ]);
  };

  const onTestConnection = (envId) => () => {
    setTestConnectionEnv(environments.find(({ id }) => id === envId));
  };

  const save = () => {
    mutation.mutate(environments);
    notification.success({ message: "Saved", duration: 1.5 });
  };

  const onDelete = (envId) => () => {
    updateEnvironments((envs) => envs.filter(({ id }) => id !== envId));
    mutation.mutate(environments.filter(({ id }) => id !== envId));
    notification.info({
      message: `Environment successfully deleted`,
      duration: 1.5,
    });
  };

  return (
    <>
      <TopControlsBlock width="auto">
        <Button onClick={save} type="primary">
          Save
        </Button>
      </TopControlsBlock>
      <ContentInner>
        {environments.map((env) => (
          <EnvironmentForm
            environment={env}
            key={env.id}
            dbTypes={DATABASE_TYPES}
            onInputChange={onInputChange(env.id)}
            onClientSelect={onClientSelect(env.id)}
            onDelete={onDelete(env.id)}
            onTestConnection={onTestConnection(env.id)}
          />
        ))}
        <Button type="default" icon={<PlusOutlined />} onClick={addEnvironment}>
          Add environment
        </Button>
      </ContentInner>
    </>
  );
}
