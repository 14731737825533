import React from "react";
import { Form, Input, Button, Col, Upload, Space, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Item } = Form;

const ACCEPTED_MIME_TYPES = [
  "application/zip",
  "application/x-zip",
  "application/x-zip-compressed",
  "multipart/x-zip",
];

export default function UploadManifestForm({
  data,
  onSubmit,
  onInputChange,
  file,
  onFileUpload,
}) {
  const props = {
    beforeUpload: (file) => {
      const isZip = ACCEPTED_MIME_TYPES.find((type) => file.type === type);
      if (isZip) {
        onFileUpload(file);
      } else {
        message.error(`${file.name} is not a zip file`);
      }

      return false;
    },
    maxCount: 1,
    showUploadList: false,
  };

  return (
    <Form
      style={{ width: "400px", marginTop: "24px" }}
      layout="vertical"
      initialValues={data}
      onFinish={onSubmit}
    >
      <Col>
        <Item
          label="Customer order id"
          name="coid"
          value={data.coid}
          onChange={onInputChange}
          rules={[{ required: true, message: "Customer order id is required" }]}
        >
          <Input name="coid" />
        </Item>
      </Col>
      <Col>
        <Space>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Select ZIP File</Button>
          </Upload>
          {file.name}
        </Space>
      </Col>
      <Button
        type="primary"
        htmlType="submit"
        style={{ width: "100%", marginTop: "24px" }}
      >
        Upload
      </Button>
    </Form>
  );
}
