import { Form, Input, Row, Col, Button } from "antd";

const { Item } = Form;

export default function LoginForm({ data, onInputChange, onSubmit }) {
  return (
    <Form layout="horizontal" onFinish={onSubmit} onChange={onInputChange}>
      <Row>
        <Col span={24}>
          <Item>
            <Input
              value={data.email}
              name="email"
              type="email"
              placeholder="Your email..."
            />
          </Item>
          <Item>
            <Input
              value={data.password}
              name="password"
              type="password"
              placeholder="Your password..."
            />
          </Item>
        </Col>
      </Row>
      <Button type="primary" onClick={onSubmit}>
        Login
      </Button>
    </Form>
  );
}
