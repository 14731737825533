import styled from "styled-components";
import { Layout } from "antd";

export const PageContent = styled.div`
  display: flex;
  width: 100%;
  min-height: 97%;
  padding: 24px;
  flex-direction: column;
  background-color: #fff;
`;

export const StyledHeader = styled(Layout.Header)`
  display: flex;
  background-color: #fff;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;
