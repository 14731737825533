import { useEffect, useState } from "react";
import { Button, Form, Select, Modal, notification } from "antd";
import { v4 as uuidv4 } from "uuid";
import { PlusOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import axios from "axios";

import { TagManagerForm } from "../../components/Forms";
import { useModal, useSocket } from "../../hooks";
import {
  EVENTS,
  TAG_TYPES,
  ROLL_MODEL,
  URLS,
  TAG_MANUFACTURER,
} from "../../constants";
import { TopControlsBlock, ContentInner, Link } from "../styled";
import { getOverageAsPercentage } from "../../helpers";

const { Item } = Form;

export default function TagManager({ token }) {
  const [selectedEnvironment, setEnvironment] = useState(null);
  const [
    isModalShown,
    setModalShown,
    onModalOkAction,
    onModalCancelAction,
  ] = useModal();
  const [orders, updateOrders] = useState([]);
  const [response, setResponse] = useState([]);

  const { data, send, get } = useSocket(EVENTS.TAG_MANAGER_CREATE);

  useEffect(() => {
    if (data) {
      data.forEach((res) => setResponse((prev) => [...prev, res]));
    }
  }, [data]);

  const { data: envData } = useQuery(
    "envData",
    () =>
      axios
        .get(URLS.FETCH_ENVIRONMENT, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => res.data),
    {
      initialData: [],
    }
  );

  const { data: companiesData } = useQuery(
    ["companiesData", selectedEnvironment],
    () =>
      axios
        .get(URLS.FETCH_COMPANIES, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            env: selectedEnvironment,
          },
        })
        .then((res) => res.data),
    { initialData: [], enabled: !!selectedEnvironment }
  );

  const updateRoll = (field, value, rollId) => {
    updateOrders((orders) =>
      orders.map((order) => {
        if (rollId !== order.id) return order;
        return { ...order, [field]: value };
      })
    );
  };

  const updateQRCOptions = (field, value, rollId) => {
    updateOrders((orders) =>
      orders.map((order) => {
        if (rollId !== order.id) return order;
        return { ...order, qrc: { ...order.qrc, [field]: value } };
      })
    );
  };

  const resetQRCOptions = (rollId) => {
    updateOrders((orders) =>
      orders.map((order) => {
        if (rollId !== order.id) return order;
        return { ...order, qrc: { ...ROLL_MODEL.qrc } };
      })
    );
  };

  const onInputChange = (rollId) => (e) => {
    const { name, value } = e.target;
    updateRoll(name, value, rollId);
  };

  const onOptionsChange = (rollId) => (e) => {
    const { name, value } = e.target;
    updateQRCOptions(name, value, rollId);
  };

  const onOptionsReset = (rollId) => (e) => {
    resetQRCOptions(rollId);
  };

  const onCompanySelect = (rollId) => (companyId) => {
    updateRoll("companyId", companyId, rollId);
  };

  const onTagManufacturerSelect = (rollId) => (value) => {
    updateRoll("tagManufacturer", value, rollId);
  };

  const onTagTypeSelect = (rollId) => (type) => {
    updateRoll("tagType", type, rollId);
    // reset tag model
    updateRoll("tagModel", '', rollId);
  };

  const onTagModelSelect = (rollId) => (model) => {
    updateRoll("tagModel", model, rollId);
  };

  const onEnvironmentSelect = (value) => {
    setEnvironment(value);
  };

  const addRoll = () => {
    updateOrders((rolls) => [...rolls, { id: uuidv4(), ...ROLL_MODEL }]);
  };

  const onDelete = (rollId) => () => {
    updateOrders((orders) => orders.filter(({ id }) => id !== rollId));
  };

  const onClone = (rollId) => () => {
    const order = orders.find(({ id }) => id === rollId);
    updateOrders((orders) => [...orders, { ...order, id: uuidv4() }]);
  };

  const getTotalTagsSize = (orderId) => {
    const order = orders.find((el) => el.id === orderId);
    if (!order) {
      return;
    }
    const totalSize = +order.size + getOverageAsPercentage(order);
    if (totalSize > 99) {
      notification.error({
        message:
          "The total number of tags cannot exceed 99 tags in one order.",
        duration: 5,
      });
      updateRoll("size", "", order.id);
      return;
    }
    return +order.size + getOverageAsPercentage(order);
  };

  const getTotalOverageInPercent = (orderId) =>
    orders.map(
      (el) =>
        orderId === el.id &&
        `${+el.overage}% (${getOverageAsPercentage(el)} tags)`
    );

  const create = () => {
    send(EVENTS.TAG_MANAGER_CREATE, {
      orders: orders.map((order) => {
        return {
          ...order,
          overage: Math.round(+(+order.size * +order.overage) / 100),
          tagsPerRoll: getTotalTagsSize(order.id) / +order.rolls,
        };
      }),
      env: selectedEnvironment,
    });
    get(EVENTS.TAG_MANAGER_CREATE);
  };

  return (
    <>
      <TopControlsBlock>
        <Item style={{ width: "50%" }} label="Environment">
          <Select onSelect={onEnvironmentSelect}>
            {envData.map((env) => (
              <Select.Option value={env.name} key={env.name}>
                {env.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Button
          type="primary"
          onClick={setModalShown()}
          disabled={!response[0]}
        >
          Download order
        </Button>
        <Modal
          title="Download order"
          width={750}
          visible={isModalShown}
          onOk={onModalOkAction()}
          onCancel={onModalCancelAction()}
        >
          {response.map((link) => (
            <div key={link}>
              Download:{" "}
              <Link disabled={!response[0]} download="sm.zip" href={link}>
                {link}
              </Link>
              <br />
            </div>
          ))}
        </Modal>
        <Button onClick={create} type="primary">
          Create orders
        </Button>
      </TopControlsBlock>
      <ContentInner>
        {orders.map((order, index) => (
          <div key={order.id}>
            <TopControlsBlock
              style={{ marginBottom: "15px" }}
              align="flex-start"
              width="100%"
            >
              <ContentInner>
                <strong>Order Information: </strong>
                Total rolls: {order.rolls || 0}; Total tags:{" "}
                {getTotalTagsSize(order.id) || 0}; Tag overage:{" "}
                {getTotalOverageInPercent(order.id)};
              </ContentInner>
            </TopControlsBlock>
            <TagManagerForm
              order={order}
              key={order.id}
              tagTypes={TAG_TYPES}
              tagManufacturer={TAG_MANUFACTURER}
              companies={companiesData}
              onInputChange={onInputChange(order.id)}
              onCompanySelect={onCompanySelect(order.id)}
              onTagModelSelect={onTagModelSelect(order.id)}
              onTagTypeSelect={onTagTypeSelect(order.id)}
              onDelete={onDelete(order.id)}
              onClone={onClone(order.id)}
              onOptionsChange={onOptionsChange(order.id)}
              onReset={onOptionsReset(order.id)}
              onTagManufacturerSelect={onTagManufacturerSelect(order.id)}
            />
          </div>
        ))}
        <Button type="default" icon={<PlusOutlined />} onClick={addRoll}>
          Add order
        </Button>
      </ContentInner>
    </>
  );
}
