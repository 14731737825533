import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import "antd/dist/antd.css";
import { useQuery } from "react-query";
import axios from "axios";

import { getAccessToken, clearAccessToken } from "./helpers";
import Layout from "./components/Layout";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import { routes } from "./components/ProtectedRoute/routes";
import { URLS } from "./constants";

function App() {
  const [accessToken, updateAccessToken] = useState(null);
  const [currentUser, setCurrentUser] = useState();

  const { refetch } = useQuery(
    "user",
    () =>
      axios
        .get(URLS.VERIFY_TOKEN, {
          params: { token: getAccessToken() || "" },
        })
        .then((res) => {
          if (res.data.status === 401) {
            clearAccessToken();
          }
          updateAccessToken(getAccessToken());
        })
  );

  useEffect(() => {
    const access_token = getAccessToken();
    if (access_token) {
      refetch();
    }
  }, [refetch]);

  useEffect(() => {
    if (accessToken) {
      axios
        .get(URLS.FETCH_CURRENT_USER, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => {
          if (res.data) {
            setCurrentUser(res.data);
          }
        });
    }
  }, [accessToken]);

  return (
    <Router>
      {!accessToken ? <Redirect to="/login" /> : <Redirect to="/tag-manager" />}
      <Route
        exact
        path="/login"
        render={() => <Login setAuthorized={updateAccessToken} />}
      />
      {accessToken && currentUser && (
        <Switch>
          <Layout user={currentUser}>
            {routes.map((route) => {
              if (route.needAuth) {
                if ((currentUser.role === "company_admin" && !route.isSuperAdmin) || currentUser.role === "super_admin") {
                  return (
                    <ProtectedRoute
                      token={accessToken}
                      key={route.path}
                      {...route}
                    />
                  );
                }
              
              }
              return null;
            })}
          </Layout>
        </Switch>
      )}
    </Router>
  );
}

export default App;
