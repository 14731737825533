import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";

import { InfoBoxWrapper } from "./styled";

const InfoBox = ({children, width}) => (
  <InfoBoxWrapper width={width}>
    <InfoCircleOutlined style={{ color: "#1890ff", margin: "3px 10px 0 0" }} />
    {children}
  </InfoBoxWrapper>
);

export default InfoBox;
