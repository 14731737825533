import { useState } from "react";
import { Form, Input, Select, Row, Col, Button, Divider, Drawer } from "antd";
import { DeleteFilled, CopyFilled, QrcodeOutlined } from "@ant-design/icons";

import { SVGQRCodes } from ".";

const { Item } = Form;

export default function TagManagerForm({
  order,
  tagTypes,
  tagManufacturer,
  companies,
  onInputChange,
  onOptionsChange,
  onCompanySelect,
  onTagTypeSelect,
  onTagModelSelect,
  onTagManufacturerSelect,
  onDelete,
  onClone,
  onReset,
}) {
  const [optionVisible, setOptionVisible] = useState(false);

  const getCompanyNameById = (id) => {
    const company = companies.find((company) => company.id === id);
    if (!company) {
      return "";
    }
    return company.name;
  };

  const onSearch = (input, option) =>
    getCompanyNameById(option.value)
      .toLowerCase()
      .includes(input.toLowerCase()) ||
    getCompanyNameById(option.value)
      .toLowerCase()
      .includes(input.toLowerCase());

  const sortedCompaniesByName = companies.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Form layout="horizontal" onChange={onInputChange}>
      <Row>
        <Col span={11}>
          <Item label="Company">
            <Select
              showSearch
              optionFilterProp="value"
              filterOption={(input, option) => onSearch(input, option)}
              onSelect={onCompanySelect}
              value={getCompanyNameById(order.companyId)}
            >
              {sortedCompaniesByName.map((company) => (
                <Select.Option value={company.id} key={company.id}>
                  {company.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col offset={2} span={11}>
          <Item label="Manufacturer">
            <Select
              onSelect={onTagManufacturerSelect}
              value={order.tagManufacturer}
            >
              {tagManufacturer.map((manufacturer) => (
                <Select.Option value={manufacturer} key={manufacturer}>
                  {manufacturer}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Item label="Number of tags">
            <Input name="size" value={order.size} placeholder="20" />
          </Item>
        </Col>
        <Col offset={1} span={6}>
          <Item label="Overage">
            <Input
              suffix="%"
              value={order.overage}
              name="overage"
              placeholder="5"
            />
          </Item>
        </Col>
        <Col offset={1} span={6}>
          <Item label="Rolls">
            <Input value={order.rolls} name="rolls" placeholder="5" />
          </Item>
        </Col>
        <Col span={5}>
          <Item label="Tag type">
            <Select onChange={onTagTypeSelect} value={order.tagType}>
              {Object.keys(tagTypes).map((type) => (
                <Select.Option value={type} key={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col offset={1} span={7}>
          <Item label="Tag model">
            <Select
              disabled={!order.tagType}
              value={order.tagModel}
              onSelect={onTagModelSelect}
            >
              {tagTypes[order.tagType]?.map((model) => (
                <Select.Option value={model} key={model}>
                  {model}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
      </Row>
      <Item label="Description">
        <Input
          name="description"
          value={order.description}
          placeholder="description"
        />
      </Item>
      <Row>
        <Row style={{ width: "19%" }}>
          <Button
            icon={<QrcodeOutlined />}
            onClick={() => setOptionVisible(true)}
          >
            QRC Options
          </Button>
          <Drawer
            width={500}
            title="QRC Options"
            placement="right"
            closable={false}
            onClose={() => setOptionVisible(false)}
            visible={optionVisible}
          >
            <SVGQRCodes
              order={order}
              onReset={onReset}
              onOptionsChange={onOptionsChange}
            />
          </Drawer>
        </Row>
        <Row justify="end" style={{ width: "81%" }}>
          <Button
            style={{ marginRight: "1rem" }}
            type="primary"
            icon={<CopyFilled />}
            onClick={onClone}
          >
            Clone
          </Button>
          <Button type="danger" icon={<DeleteFilled />} onClick={onDelete}>
            Delete
          </Button>
        </Row>
      </Row>
      <Divider />
    </Form>
  );
}
