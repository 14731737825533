import { notification } from "antd";
import { useEffect, useState, useRef } from "react";
import io from "socket.io-client";

export default function useSocket(endpoint, event) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const webSocketRef = useRef(null);

  useEffect(() => {
    webSocketRef.current = io(process.env.REACT_APP_SERVER_HOST, {
      transports: ["websocket"],
    });

    webSocketRef.current.on("connect", function () {
      console.info("WebSocket connected");
    });

    webSocketRef.current.on("exception", function (data) {
      console.error("WebSocket exception =>", data);
      notification.error({ message: `${data.status}: ${data.message}` });
    });

    webSocketRef.current.on("disconnect", function () {
      console.error("WebSocket disconnected");
    });

    webSocketRef.current.on(event, (data) => {
      setData(data);
      setLoading(false);
    });

    return () => {
      webSocketRef.current.disconnect();
    };
  }, [event]);

  const send = (eventName, data) => {
    setLoading(true);
    webSocketRef.current.emit(eventName, data);
    notification.info({ message: `Orders creating`, duration: 2 });
  };

  const get = (eventName) => {
    setLoading(true);
    webSocketRef.current.on(eventName, (data) => {
      setData(data);
      setLoading(false);
      notification.success({ message: `Orders successfully created`, duration: 2 });
    });
  };

  return { data, loading, send, get };
}
