import React, { useState } from "react";
import axios from "axios";
import { message, Spin } from "antd";

import { URLS } from "../../constants";
import { UploadManifestForm } from "../../components/Forms";
import InfoBox from "../../components/InfoBox";
import { ContentInner } from "../styled";
import { ManifestWrapper, ManifestInner } from "./styled";

export default function Manifest({ token }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataForm, setDataForm] = useState({});
  const [fileUpload, setFileUpload] = useState({});

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setDataForm({ ...dataForm, [name]: value });
  };

  const uploadManifest = async (formData) => {
    try {
      const response = await axios.post(URLS.MANIFEST, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return { data: response ? response.data : null };
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data?.message || "Something went wrong";
        message.error(errorMessage);
      }
    }
  };

  const onSubmit = async () => {
    if (!fileUpload.name) {
      message.error("There is no file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("coid", dataForm.coid);
    formData.append("file", fileUpload);
    setIsLoading(true);
    const response = await uploadManifest(formData);

    if (response) {
      setFileUpload({});
      message.success("The manifest has been uploaded successfully");
    }

    setIsLoading(false);
  };

  return (
    <Spin spinning={isLoading}>
      <ManifestWrapper>
        <ContentInner>
          <ManifestInner>
            <InfoBox width="400px">
              Please zip all required files and upload it via the field
              underneath.
            </InfoBox>
            <UploadManifestForm
              data={dataForm}
              onSubmit={onSubmit}
              onInputChange={onInputChange}
              file={fileUpload}
              onFileUpload={setFileUpload}
            />
          </ManifestInner>
        </ContentInner>
      </ManifestWrapper>
    </Spin>
  );
}
