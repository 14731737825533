import { Form, Input, Row, Col, Button, Divider } from "antd";

import { StyledColorPicker } from "./styled";

const { Item } = Form;

export default function SVGQRCodes({ order, onOptionsChange, onReset }) {
  return (
    <Form layout="horizontal" onChange={onOptionsChange}>
      <Row>
        <Col span={5}>
          <Item label="Padding">
            <Input
              value={order.qrc.padding}
              name="padding"
              placeholder="16"
              addonAfter="px"
            />
          </Item>
        </Col>
        <Col span={5} offset={1}>
          <Item label="Width">
            <Input
              value={order.qrc.width}
              name="width"
              placeholder="16"
              addonAfter="px"
            />
          </Item>
        </Col>
        <Col span={5} offset={1}>
          <Item label="Height">
            <Input
              value={order.qrc.height}
              name="height"
              placeholder="16"
              addonAfter="px"
            />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Item label="Color">
            <Input
              value={order.qrc.fgColor}
              type="text"
              name="fgColor"
              placeholder="#000000"
              addonAfter={
                <StyledColorPicker
                  value={order.qrc.fgColor}
                  name="fgColor"
                  onChange={onOptionsChange}
                  type="color"
                />
              }
            />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Item label="Background color">
            <Input
              value={order.qrc.bgColor}
              type="text"
              name="bgColor"
              placeholder="#ffffff"
              addonAfter={
                <StyledColorPicker
                  value={order.qrc.bgColor}
                  name="bgColor"
                  onChange={onOptionsChange}
                  type="color"
                />
              }
            />
          </Item>
        </Col>
      </Row>
      <Row justify="end">
        <Button onClick={onReset}>Reset</Button>
      </Row>
      <Divider />
    </Form>
  );
}
