import { Menu as AntdMenu } from "antd";
import { Link, useLocation } from "react-router-dom";

import { routes } from "../ProtectedRoute/routes";

export default function Menu({ role }) {
  const location = useLocation();

  return (
    <AntdMenu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[
        location.pathname === "/login" ? "/tag-manager" : location.pathname,
      ]}
    >
      {routes.map((route) => {
        if (route.needAuth) {
          if ((role === "company_admin" && !route.isSuperAdmin) || role === "super_admin") {
            return (
              <AntdMenu.Item key={route.path} icon={route.icon}>
                <Link to={route.to}>{route.title}</Link>
              </AntdMenu.Item>
            );
          }
        }
        return null;
      })}
    </AntdMenu>
  );
}
