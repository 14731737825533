import { Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) => <Component {...rest} {...props} />}
    />
  );
};

export default ProtectedRoute;
