import { notification, Row } from "antd";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";

import LoginForm from "../../components/Forms/login";
import { URLS } from "../../constants";
import { ContentInner } from "../styled";
import { setAccessToken } from "../../helpers";

export default function Login({ setAuthorized }) {
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const { refetch } = useQuery(
    "login",
    () =>
      axios.post(URLS.FETCH_LOGIN, data).then((res) => {
        if (res.data.status) {
          notification.error({
            message: `Error ${res.data.status}: ${res.data.message}`,
          });
        }
        return res.data;
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        setAuthorized(data.access_token);
        setAccessToken(data.access_token);
      },
    }
  );

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const onSubmit = async () => refetch();

  return (
    <Row
      justify="center"
      align="center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <ContentInner style={{ width: "30%" }}>
        <Row>
          <h2> Login to the DIRX2 Administration Console </h2>
        </Row>
        <LoginForm
          data={data}
          onInputChange={onInputChange}
          onSubmit={onSubmit}
        />
      </ContentInner>
    </Row>
  );
}
