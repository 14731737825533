import styled from "styled-components";

export const StyledIcon = styled.div`
  & > * {
    font-size: 1.1rem;
    color: ${({ color }) => color || "rgba(0, 0, 0, 0.45)"};
    &:hover {
      opacity: 0.7;
    }
  }
`;
