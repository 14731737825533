import { Form, Input, Select, Row, Col, Button, Divider } from "antd";
import { DeleteFilled, ApiOutlined } from "@ant-design/icons";

const { Item } = Form;

export default function EnvironmentForm({
  environment,
  dbTypes,
  onInputChange,
  onClientSelect,
  onDelete,
  onTestConnection,
}) {
  return (
    <Form
      layout="horizontal"
      initialValues={environment}
      onChange={onInputChange}
    >
      <Row>
        <Col span={10}>
          <Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input name="name" placeholder="local" />
          </Item>
        </Col>
        <Col offset={4} span={10}>
          <Item
            label="Database host"
            name="host"
            required
            rules={[
              () => ({
                validator(_, value = "") {
                  const rex = new RegExp(
                    /^[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/gm
                  );
                  if (!rex.exec(value)) {
                    return Promise.reject("Invalid value");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input name="host" placeholder="example.com" />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Item
            label="Database name"
            name="database"
            rules={[{ required: true, message: "Database name is required" }]}
          >
            <Input name="database" placeholder="database" />
          </Item>
        </Col>
        <Col offset={4} span={10}>
          <Item
            label="Database port"
            name="port"
            required
            rules={[
              () => ({
                validator(_, value = "") {
                  const rex = new RegExp(/[0-9]{1,20}/gi);
                  if (!rex.exec(value)) {
                    return Promise.reject("Invalid value");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input name="port" placeholder="3000" />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Item
            label="Database username"
            name="username"
            rules={[{ required: true, message: "Username is required" }]}
          >
            <Input name="username" placeholder="username" />
          </Item>
        </Col>
        <Col offset={4} span={10}>
          <Item
            label="Database password"
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input name="password" placeholder="password" />
          </Item>
        </Col>
      </Row>
      <Row>
        <Col span={10}>
          <Item label="Database type" required>
            <Select defaultValue={environment.client} onChange={onClientSelect}>
              {Object.keys(dbTypes).map((type) => (
                <Select.Option value={dbTypes[type]} key={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col offset={4} span={10}>
          <Item
            label="Server URL"
            name="serverUrl"
            required
            rules={[
              () => ({
                validator(_, value = "") {
                  const rex = new RegExp(
                    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}/
                  );
                  if (!rex.exec(value)) {
                    return Promise.reject("Invalid value");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input name="serverUrl" placeholder="http://example.com" />
          </Item>
        </Col>
      </Row>
      <Item label="Description">
        <Input
          name="description"
          placeholder="description"
          defaultValue={environment.description}
        />
      </Item>
      <Row justify="end">
        <Col
          span={8}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button type="" icon={<ApiOutlined />} onClick={onTestConnection}>
            Test connection
          </Button>
          <Button type="danger" icon={<DeleteFilled />} onClick={onDelete}>
            Delete
          </Button>
        </Col>
      </Row>
      <Divider />
    </Form>
  );
}
