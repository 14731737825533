import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Select, Space, Tooltip, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";

import { URLS } from "../../constants";
import { ContentInner } from "../styled";

const { Item } = Form;

export default function CompanyId({ token }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [environment, setEnvironment] = useState(null);
  const [companyId, setCompanyId] = useState("");

  const { data: envData } = useQuery(
    "envData",
    () =>
      axios
        .get(URLS.FETCH_ENVIRONMENT, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => res.data),
    {
      initialData: [],
    }
  );

  const { data: companiesData } = useQuery(
    ["companiesData", environment],
    () =>
      axios
        .get(URLS.FETCH_COMPANIES, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            env: environment,
          },
        })
        .then((res) => res.data),
    { initialData: [], enabled: !!environment }
  );

  const onEnvironmentSelect = (value) => {
    setEnvironment(value);
  };

  const sortedCompaniesByName = companiesData.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const getCompanyNameById = (id) => {
    const company = companiesData.find((company) => company.id === id);
    if (!company) {
      return "";
    }
    return company.name;
  };

  const onSearch = (input, option) =>
    getCompanyNameById(option.value)
      .toLowerCase()
      .includes(input.toLowerCase());

  const onCompanySelect = (id) => {
    console.log(id);
    setCompanyId(id);
  };

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(companyId);
    messageApi.open({
      type: "success",
      content: "Company ID is copied to the clipboard",
    });
  };

  return (
    <ContentInner>
      {contextHolder}
      <strong style={{ marginBottom: "6px" }}>Select the environment</strong>
      <Item style={{ width: "50%" }} label="Environment">
        <Select onSelect={onEnvironmentSelect}>
          {envData.map((env) => (
            <Select.Option value={env.name} key={env.name}>
              {env.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      {environment && (
        <>
          <strong style={{ marginBottom: "6px" }}>Select the company</strong>
          <Item label="Company">
            <Select
              showSearch
              optionFilterProp="value"
              filterOption={(input, option) => onSearch(input, option)}
              onSelect={onCompanySelect}
            >
              {sortedCompaniesByName.map((company) => (
                <Select.Option value={company.id} key={company.id}>
                  {company.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </>
      )}
      {companyId && (
        <>
          <strong style={{ marginBottom: "6px" }}>Company ID</strong>
          <Space>
            <strong style={{ fontSize: "16px" }}>{companyId}</strong>
            <Tooltip title="Copy to clipboard">
              <Button
                type="primary"
                icon={<CopyOutlined />}
                size="small"
                onClick={onCopyToClipboard}
              />
            </Tooltip>
          </Space>
        </>
      )}
    </ContentInner>
  );
}
