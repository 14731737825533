import styled from "styled-components";

export const InfoBoxWrapper = styled.div`
  display: flex;
  border: 1px solid #1890ff;
  font-size: 14px;
  padding: 10px;
  border-radius: 7px;
  line-height: 18px;
  width: ${({ width }) => (width ? `${width}` : "auto")};
`;
